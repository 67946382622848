import { BigNumber } from 'bignumber.js';

export const formatToZeroPrefixedNumber = (value: number | string) =>
  BigNumber(value).isPositive() && BigNumber(value).gte(10)
    ? BigNumber(value).toString()
    : `0${BigNumber(value).toString()}`;

export const formateDate = (date: string) => new Date(date).toLocaleTimeString('en-GB');

// format of pearl name like goldOre to 'gold ore' adding spaces
export const addSpaces = (pearlMethod: string): string => {
  return pearlMethod.replace(/([A-Z])/g, ' $1').trim();
};
